import { SetStateAction, MouseEvent } from "react";
import { useChains } from "../../../components/FetchConfig";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "../../../components/MuiGenerals";

interface propsFace {
  label?: string;
  setChoice: (value: SetStateAction<any>) => void;
  choice: string;
}
export default function SolChainSelection(props: propsFace) {
  const { label = "", choice, setChoice } = props;
  const { chainObj } = useChains();
  const solObjArr = Object.entries({ ...chainObj }).filter(
    ([key, value]: any) => {
      return (
        (value.chain_type == 5 && value.chain_id == "2") ||
        (value.chain_type == 5 && value.chain_id == "1")
      );
    }
  );
  const onSelect = (e: MouseEvent<HTMLElement>) => {
    const value = (e.target as HTMLElement).dataset.value || "";
    const output = value === choice ? "" : value;
    setChoice(output);
  };

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select defaultValue="" value={choice} onClick={onSelect}>
        {solObjArr
          .sort((a, b) => a[1].name.localeCompare(b[1].name))
          .map(([key, { name }]) => (
            <MenuItem key={key} value={key}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
