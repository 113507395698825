import "../../src/App.module.scss";

import { useState } from "react";
import { Menu as RaMenu, MenuItemLink, useTranslate } from "react-admin";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import WalletManagerLogo from "../components/WalletManagerLogo";
import { usePermission } from "../hooks/";
import { routeList } from "../hooks/useRoutePermission";
import { FeatureCodes, versionNumber } from "../utils/constant";
import { useZusListStore } from "../zustand/store";
import {
  PendingCount,
  useAllPendingOperationsCount,
} from "./AssetManagement/MerchantOperation/MerchantOperation";
import { useAllPendingDepositRecordCount } from "./Ledger/WalletManagerDepositRecord/WalletManagerDepositRecord";

const sx = {
  logo: {
    padding: "30px",
  },
  menuPadding: {
    paddingLeft: "10px",
  },
  expanded: {
    color: "#4A90F7",
    boxShadow: "none",
  },
  notExpanded: {
    color: "#656A79",
    boxShadow: "none",
  },
  muiAccordion: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      transform: "rotate(270deg)",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(360deg)",
      color: "#4A90F7",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      maxHeight: "30px",
      margin: "5px 0px",
    },
  },
  accordionSummary: {
    "&.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px",
    },
  },
  accordionDetails: {
    padding: "5px",
  },
};

export default function Menu(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);
  const [selected, setSelected] = useState("");
  const { hasPermission } = usePermission();
  // const L = useRoutePermission().list;
  const L = routeList;
  const onAssetManagementOpen = translate(`menu.assetManagement`) === selected;
  const onLedgerOpen = translate(`menu.ledger`) === selected;
  const merchantOperationCountFetchEnabled =
    hasPermission(FeatureCodes.assetManagement.MerchantOperation.prefix) &&
    onAssetManagementOpen;
  const depositRecordCountFetchEnabled =
    hasPermission(FeatureCodes.ledger.WalletManagerDepositRecord.prefix) &&
    onLedgerOpen;
  const pendingOperationsCount = {
    merchantOperation: useAllPendingOperationsCount(
      merchantOperationCountFetchEnabled
    ).all,
    ledgerDepositRecord: useAllPendingDepositRecordCount(
      depositRecordCountFetchEnabled
    ),
  };
  //###add-new-page
  return (
    <RaMenu
      {...props}
      style={{
        paddingTop: "0",
        paddingBottom: "0",
        marginTop: "0",
        marginBottom: "0",
      }}
    >
      <div style={sx.menuPadding}>
        <div style={sx.logo}>
          <WalletManagerLogo />
        </div>
        <div style={{ height: "calc(100vh - 9.5rem)" }}>
          <MenuStack
            selected={selected}
            setSelected={setSelected}
            title={t("assetManagement")}
            meta={{ pendingOperationsCount }}
            list={[
              L.assetList,
              L.batchSweep,
              L.sweepRequestRecord,
              L.batchWithdraw,
              L.withdrawRequestRecord,
              L.operationRecords,
              L.operationTransactionRecords,
              L.merchantOperation,
              L.withdrawSetting,
              L.withdrawApprovalSetting,
              L.withdrawApprovalCheckingTimeSetting,
            ]}
          />
          <MenuStack
            selected={selected}
            setSelected={setSelected}
            title={t("walletManagement")}
            list={[
              L.walletManagement,
              L.walletBalance,
              L.totalWalletBalance,
              L.walletBalanceAdjustment,
            ]}
          />
          <MenuStack
            selected={selected}
            setSelected={setSelected}
            title={t("report")}
            list={[
              L.merchantReport,
              L.clientReport,
              L.transactionReport,
              L.depositAndWithdrawalStatistics,
              L.messageManagement,
            ]}
          />
          <MenuStack
            selected={selected}
            setSelected={setSelected}
            title={t("tools")}
            list={[
              L.blockRescan,
              L.reBroadcast,
              L.reSubmit,
              L.yubiKeyRegister,
              L.userApprovalManagement,
              L.balanceCheckpoint,
              // L.addressGeneration, //temporary remove
              L.nonceTool,
              L.utxoToolManagement,
              L.auditLog,
            ]}
          />
          <MenuStack
            selected={selected}
            setSelected={setSelected}
            title={t("ledger")}
            meta={{ pendingOperationsCount }}
            list={[
              L.walletManagerDepositRecord,
              L.withdrawRequest,
              L.transferRequest,
              L.clientManagement,
              L.clientBalance,
              L.ledgerFreezeRequest,
              L.ledgerFrozenFund,
              L.ledgerTransactionRecord,
            ]}
          />
        </div>
        <Box
          sx={{
            textAlign: "center",
            color: "black",
            width: "320px",
            position: "fixed",
            left: 0,
            bottom: "12px",
          }}
        >
          {t("version")}: {versionNumber}
        </Box>
      </div>
    </RaMenu>
  );
}

function MenuStack(props: any) {
  const { hasPermission } = usePermission();
  const zusListStore = useZusListStore();
  const resetZusList = () => zusListStore.clear();
  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);
  const { title, list = ["", "", ""], selected, setSelected } = props;
  const expanded = selected === title;
  const [selectedPagePathname, setSelectedPagePathname] = useState(
    window.location.pathname.substring(1)
  );
  const toggle = () =>
    setSelected((key: string) => {
      if (key === title) return "";
      return title;
    });
  const canRender = (key: string) => {
    if (key === "*****") return true; //for temporary use
    return hasPermission(key);
  };
  const pendingOperationsCount: {
    merchantOperation: string;
    ledgerDepositRecord: string;
  } = props?.meta?.pendingOperationsCount || {
    merchantOperation: "0",
    ledgerDepositRecord: "0",
  };
  const genItem = ([text, to, permission = ""]: any = []) => {
    if (!canRender(permission)) return false;
    return (
      <Box sx={{ width: "calc(100% - .5rem)", display: "flex" }} key={text}>
        <MenuItemLink
          to={to}
          primaryText={t(text)}
          sx={{ flex: "1" }}
          onClick={
            selectedPagePathname !== to
              ? () => {
                  resetZusList();
                  setSelectedPagePathname(to);
                }
              : undefined
          }
        />
        {(to === "merchantOperation" ||
          to === "walletManagerDepositRecord") && (
          <PendingCount
            count={
              to === "merchantOperation"
                ? Number(pendingOperationsCount.merchantOperation)
                : Number(pendingOperationsCount.ledgerDepositRecord)
            }
          />
        )}
      </Box>
    );
  };
  const subMenus = list
    .filter((ele: any) => {
      if (!ele) console.error("empty array in subMenus");
      return ele;
    })
    .map(genItem);
  const isHidden = subMenus.filter((dom: any) => dom).length === 0;
  if (isHidden) return <></>;
  return (
    <Accordion
      sx={{
        color: expanded ? sx.expanded : sx.notExpanded,
        ...sx.muiAccordion,
      }}
      expanded={expanded}
      onChange={toggle}
    >
      <AccordionSummary
        expandIcon={<ExpandCircleDownIcon />}
        aria-controls={title}
        sx={sx.accordionSummary}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={sx.accordionDetails}>
        <Typography variant="h6">{list.map(genItem)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
