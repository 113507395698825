import { useState } from "react";
import {
  enumOperationStatus,
  enumOperationBatchStatus,
  enumMerchantOperationStatus,
  enumOperationTransactionStatus,
  enumWalletStatus,
  enumWalletType,
  enumDirectionObj,
  enumIsFeeObj,
  enumTransactionStatus,
  enumMerchantStatus,
  enumTransationType,
  enumCallbackStatus,
  enumAdjustmentStatus,
  enumMessageDataTypes,
  enumMessageCallbackStatus,
  enumCreatorType,
  callbackSettingSendTypes,
} from "../utils/constant";

const initEnumConfig = {
  enumOperationStatus,
  enumOperationBatchStatus,
  enumMerchantOperationStatus,
  enumOperationTransactionStatus,
  enumWalletStatus,
  enumWalletType,
  enumDirectionObj,
  enumIsFeeObj,
  enumTransactionStatus,
  enumMerchantStatus,
  enumTransationType,
  enumCallbackStatus,
  enumAdjustmentStatus,
  enumMessageDataTypes,
  enumMessageCallbackStatus,
  callbackSettingSendTypes,
  enumCreatorType,
};
export default function useConfig() {
  const [enumConfig, setEnumConfig] = useState(initEnumConfig);
  return enumConfig;
}
