import { Button } from "../../components/MuiGenerals";
import RetryIcon from "@mui/icons-material/Replay";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { FeatureCodes, dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import { listFace, resetMessages } from "../../api/merchant";
import { useTranslate } from "react-admin";
import Prompter, { usePrompter } from "../../components/Prompter";
import { useAlerting, usePermission, useConfig } from "../../hooks";
import { CustomPagination } from "../../components/CustomPagination";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
const Pkey = FeatureCodes.report.MessageManagement;
interface propsFace {
  list: listFace;
  listMapping: any;
  page: number;
  isSearchBtnClick: boolean;
  setPage: (page: number, details?: GridCallbackDetails<any>) => void;
}
export default function MessageManagementList(props: propsFace) {
  const { list, setPage, page, listMapping, isSearchBtnClick } = props;
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string) => translate(`message.${key}`);
  const contentConfig = { ...useConfig(), translate };
  const content: GridRowsProp = listMapping("key", list.rows, contentConfig);
  const [prompter, setPromter] = usePrompter();
  const { alerting } = useAlerting();
  const retryApi = async (id: string) => {
    const res = await resetMessages(id);
    if (res !== 1) return;
    const text = `${t("id")} ${id} ${t("hasReset")}`;
    alerting("success", text);
  };
  const onRetry = (id: string) =>
    setPromter(() => retryApi(id), {
      title: t("resetConfirmation"),
      yesText: t("confirm"),
      noText: t("cancel"),
    });
  const cellButton = (params: GridRenderCellParams) => {
    const id = String(params.row.id);
    return (
      <Button onClick={() => onRetry(id)} color="secondary" variant="contained">
        <RetryIcon />
      </Button>
    );
  };
  const columns: GridColDef[] = [
    useGenGridCol("id", t("id")),
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("merchant_order_id", t("merchant_order_id")),
    useGenGridCol("operation_id", t("operation_id")),
    useGenGridCol("data_type", t("data_type")),
    useGenGridCol("status", t("status")),
    useGenGridCol("client_id", t("client_id")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("callback_seq", t("callback_seq")),
    useGenGridCol("callback_base_url", t("callback_base_url")),
    useGenGridCol("attempt_count", t("attempt_count")),
    useGenGridCol("callback_status", t("callback_status")),
    useGenGridCol("tx_hash", t("tx_hash")),
    useGenGridCol("date_received", t("date_received")),
    useGenGridCol(
      "date_last_callback_attempt",
      t("date_last_callback_attempt")
    ),
    useGenGridCol("1", t("reset"), {
      renderCell: cellButton,
    }),
  ].filter((col) => {
    if (col.field === "1" && !hasPermission(Pkey.Reset)) {
      return false;
    }
    return true;
  }) as GridColDef[];

  const totalRecords = list.count;
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <div>
      <Prompter {...prompter} />
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay: () => (
            <>
              {!isSearchBtnClick ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div>{t("reminder")}</div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  {t("noRow")}
                </div>
              )}
            </>
          ),
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
}
