import { useTranslate } from "react-admin";

import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { useAlerting, useConfig } from "../../hooks";
import { dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import { useZusDialogStore } from "../../zustand/store";
import CheckPointAction from "../Common/CheckPointAction";
import { CustomPagination } from "../CustomPagination";
import { Container, Tooltip } from "../MuiGenerals";
import { NoRowsOverlay } from "../NoRowsOverlay";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";

export default function WalletBalanceList(props: any) {
  const translate = useTranslate();
  const zusDialog = useZusDialogStore();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const { alerting } = useAlerting();
  const { enumWalletType } = useConfig();
  const { list, page, setPage, listMapping, refresh } = props;
  const totalRecords = list.count;
  const content: GridRowsProp = listMapping("key", list.rows, { t, translate });

  // const [updateWalletData, setUpdateWalletData] = useState(initUpdateWalletData);
  const WalletName = (params: any) => {
    const wallet_address = params.row?.wallet_address;
    const item = list.rows.find(
      (item: any) => item.wallet_address === wallet_address
    );

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Tooltip title={item.wallet?.display_name}>
          <span
            style={{
              paddingLeft: "0.5rem",

              textOverflow: "ellipsis",

              overflow: "hidden",
              whiteSpace: "nowrap",
              lineHeight: 2,
            }}
          >
            {item.wallet?.display_name}
          </span>
        </Tooltip>
      </div>
    );
  };
  const columns: GridColDef[] = [
    useGenGridCol("display_name", t("display_name"), {
      minWidth: 140,
      renderCell: WalletName,
    }),
    useGenGridCol("wallet_name", t("wallet_name"), { minWidth: 120 }),
    useGenGridCol("wallet_type", t("wallet_type"), { minWidth: 120 }),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("balance", t("balance")),
    useGenGridCol("wallet_address", t("walletAddress")),
    useGenGridCol("status", t("walletStatus")),
    useGenGridCol("latest_checkpoint_id", t("latest_checkpoint_id"), {
      minWidth: 120,
      renderCell: CheckPointAction,
    }),
    useGenGridCol("latest_checked_blocked_no", t("latest_checked_blocked_no"), {
      minWidth: 145,
    }),
    useGenGridCol(
      "certified_with_on_chain_balance",
      t("certified_with_on_chain_balance"),
      { minWidth: 175 }
    ),
    useGenGridCol(
      "last_certified_checkpointId",
      t("last_certified_checkpointId"),
      { minWidth: 155, renderCell: CheckPointAction }
    ),
    useGenGridCol("created_date", t("created_date")),
    useGenGridCol("last_modified_date", t("last_modified_date")),
    useGenGridCol("wallet_settlement_time", t("wallet_settlement_time"), {
      minWidth: 175,
    }),
  ];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <Container maxWidth={false} disableGutters>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </Container>
  );
}
