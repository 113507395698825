import { FeatureCode as nodetypes } from "@wallet-manager/node-types";
import LedgerDirection from "@wallet-manager/node-types/dist/src/ledger/enums/LedgerDirection";
import LedgerTransactionType from "@wallet-manager/node-types/dist/src/ledger/enums/LedgerTransactionType";
import EnumClientType from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerClientType";

//version no
export const versionNumber = "v12.17.0";

export interface FreeObj {
  [code: string]: string;
}

export const enumYesAndNo = {
  yes: "yes",
  no: "no",
};

export enum EnumNoRiskWallet {
  includeNoRiskWallet = 1,
  notIncludeNoRiskWallet = 0,
}

export const enumClientType = {
  system: EnumClientType.System.toString(),
  house: String(EnumClientType.House),
  client: String(EnumClientType.Client),
};

export const enumLedgerTransactionType = {
  Deposit: String(LedgerTransactionType.Desposit),
  Withdraw: String(LedgerTransactionType.Withdraw),
  Transfer: String(LedgerTransactionType.Transfer),
  WithdrawFee: String(LedgerTransactionType.WithdrawFee),
  TransferFee: String(LedgerTransactionType.TransferFee),
  WithdrawRefund: String(LedgerTransactionType.WithdrawRefund),
  WithdrawFeeRefund: String(LedgerTransactionType.WithdrawFeeRefund),
  FreezeBalance: String(LedgerTransactionType.FreezeBalance),
  UnFreezeBalance: String(LedgerTransactionType.UnFreezeBalance),
};

export const enumLedgerDirection = {
  IN: String(LedgerDirection.IN),
  OUT: String(LedgerDirection.OUT),
};

export const enumUtxoStatus: FreeObj = {
  unused: "0",
  used: "1",
  invalid: "2",
};
//Please select Chain Name and enter Wallet Address for searching records
// 请选择链名称及输入钱包地址搜寻纪录
export const enumApprovalReason: FreeObj = {
  auto_approval_disable: "0", //When Auto Approve config is not open
  auto_approval_amount_threshold: "1", //exceeded auto approve threshold
  auto_approval_hourly_limit_amount: "2", //exceeded auto-approve hourly limit
  all_approval_hourly_limit_amount: "3", //exceeded total hourly limit
  to_address_hourly_limit_amount: "4", //exceeded to_address daily maximum transaction total amount
  to_address_hourly_limit_count: "5", //exceeded to_address daily maximum no. of transaction
  prepare_txn_retry: "6", //Prepare Tx Failed: balance not enough
  exceeded_estimated_miner_fee: "7", //exceeded Estimated Miner Fee
  exceeded_resubmit_hour_limit: "8", //exceeded Estimated Miner Fee
};
//Enum for HistoryTab
export const enumBroadcastBatchStatus = {
  SubmitFailed: "-4",
  SignFailed: "-3",
  Failed: "-2",
  Reject: "-1",
  Requested: "1",
  Submitted: "2",
  Executing: "3",
  Completed: "4",
  PartialCompleted: "5",
  Signed: "6",
} as const;

export const operationCreatorFilterEnum: FreeObj = {
  "0": "all",
  "1": "Api Withdraw",
  "2": "Batch Withdraw",
  "3": "Sweep",
};
export const enumCreatorType: FreeObj = {
  // "0": "CreatorUnknown",
  "1": "CreatorPortal",
  "2": "CreatorApi",
};
export const enumNewCreatorType = {
  CreatorPortal: "1",
  CreatorApi: "2",
} as const;

export const enumActions = {
  re_broadcast: "1",
  confirmFailure: "2",
};

//keep // FunctionName=Name
export const enumFunctionName: FreeObj = {
  0: "asset_list",
  1: "batch_sweep",
  2: "batch_withdraw",
  3: "operation_transaction_records",
  4: "merchant_operation",
  5: "withdraw_approval_setting",
  6: "merchant_wallet_management",
  // 7: "wallet_balance_adjustment",
  8: "wallet_balance_adjustment",
  9: "message_management",
  10: "block_rescan",
  11: "re_broadcast",
  12: "yubikey_register",
  13: "user_approval_management",
  14: "account_setting",
  15: "address_generation",
  16: "login",
  17: "wallet_management",
  18: "ledger_deposit_record",
  19: "ledger_withdraw_request",
  20: "ledger_transfer_request",
  21: "ledger_client_management",
};

//keep  // Operation==Type
export const enumOperation: FreeObj = {
  0: "Create_record",
  1: "Edit",
  2: "Approve",
  3: "Reject",
  4: "Delete",
  5: "Rescan",
  6: "Re_broadcast",
  7: "YubiKey_register",
  8: "Login",
  9: "Switch_to_manual_approval_process",
};

export const enumWalletBalanceAdjustmentApproval: FreeObj = {
  approve: "approve",
  reject: "reject",
};

export const logoutCodes = ["-7", "-8"];

export const enumChainType: FreeObj = {
  "1": "BTC",
  "2": "ETH",
  "3": "TRON",
  "4": "XRP",
  "5": "SOL",
  "6": "BCH",
  "7": "LTC",
  "8": "ADA",
  "9": "DOGE",
};
export const enumChainIdToType: FreeObj = {
  BTC: "1",
  ETH: "2",
  TRON: "3",
  XRP: "4",
  SOL: "5",
  BCH: "6",
  LTC: "7",
  ADA: "8",
  DOGE: "9",
};

export const addressProps = {
  client_id: "client_id",
  chainId: "chainId",
};

export const transType = {
  clientDeposit: "Client Deposit",
  clientWithdrawal: "Client Withdrawal",
};
export interface chainObjFace {
  [code: string]: {
    key: string;
    id: string;
    name: string;
    type: string;
  };
}

//only for solana and Dev.
// export const

export const chainKeyAndName = {
  AVAXC: { key: "AVAXC", name: "Avalanche C-Chain" },
  FUJIC: { key: "FUJIC", name: "Fuji C-chain" },
  ADA: { key: "ADA", name: "Cardano" },
  ADAT: { key: "ADAT", name: "Cardano Testnet" },
  LTC: { key: "LTC", name: "Litecoin" },
  LTCT: { key: "LTCT", name: "Litecoin Testnet" },
  SOL: { key: "SOL", name: "Solana" },
  SOLD: { key: "SOLD", name: "Solana Devnet" },
  BTC: { key: "BTC", name: "Bitcoin" },
  ETH: { key: "ETH", name: "Ethereum" },
  RIN: { key: "RIN", name: "Rinkeby" },
  BSC: { key: "BSC", name: "Binance" },
  BSCT: { key: "BSCT", name: "Binance Testnet" },
  XRPT: { key: "XRPT", name: "Ripple Testnet" },
  MATIC: { key: "MATIC", name: "Polygon" },
  MUM: { key: "MUM", name: "Mumbai" },
  SEP: { key: "SEP", name: "Sepolia" },
  TRX: { key: "TRX", name: "Tron" },
  XRP: { key: "XRP", name: "Ripple" },
  OKC: { key: "OKC", name: "OKX Chain" },
  OKCT: { key: "OKCT", name: "OKX Chain Testnet" },
  BCH: { key: "BCH", name: "Bitcoin Cash" },
  BCHT: { key: "BCHT", name: "Bitcoin Cash Testnet" },
  DOGE: { key: "DOGE", name: "Dogecoin" },
  DOGET: { key: "DOGET", name: "Dogecoin Testnet" },
  ARB: { key: "ARB", name: "Arbitrum One" },
  ARBT: { key: "ARBT", name: "Arbitrum Sepolia" },
  AMOY: { key: "AMOY", name: "Amoy" },
};
export const enumOperationType = {
  Withdraw: "1",
  Sweep: "2",
} as const;

export const enumPortalUserOperStatus = {
  Disabled: "-1",
  Enabled: "1",
} as const;
export const enumWalletsStatus = {
  Disabled: "-1",
  Enabled: "1",
} as const;
export const enumPortalUserYubiKeyStatus = {
  Unbound: "-1",
  Pending: "0",
  Bound: "1",
} as const;
export const enumPortalUserProposalStatus = {
  Rejected: "-1",
  PendingProposalApproval: "1",
  Approved: "2",
} as const;

//fixed order by requirement WM-1124
export const enumYubikeyProposalType = {
  YubiKeyRegistration: "1",
  UpdateYubiKey: "6", //Change YubiKey
  UnbindYubiKey: "5",
  RebindYubiKey: "4",
  EnableApprovalPermission: "2",
  DisableApprovalPermission: "3",
} as const;
export const enumTimezone = {
  timezone0: "+0",
  timezone1: "+1",
  timezone2: "+2",
  timezone3: "+3",
  timezone4: "+4",
  timezone5: "+5",
  timezone6: "+6",
  timezone7: "+7",
  timezone8: "+8",
  timezone9: "+9",
  timezone10: "+10",
  timezone11: "+11",
  timezone12: "+12",
  timezone13: "-11",
  timezone14: "-10",
  timezone15: "-9",
  timezone16: "-8",
  timezone17: "-7",
  timezone18: "-6",
  timezone19: "-5",
  timezone20: "-4",
  timezone21: "-3",
  timezone22: "-2",
  timezone23: "-1",
  // beijing:"8",
} as const;

export const enumOperationStatus: FreeObj = {
  "-4": "SubmitFailed",
  "-3": "SignFailed",
  "-2": "Failed",
  "-1": "Rejected",
  "1": "Requested",
  "2": "Submitted",
  "3": "Executing",
  "4": "Completed",
  "6": "Signed",
  "7": "Stucking",
};

//depecrated
export const enumOperationBatchStatus: FreeObj = {
  "-4": "SubmitFailed",
  "-3": "SignFailed",
  "-2": "Failed",
  "-1": "Rejected",
  "1": "Requested",
  "2": "Submitted",
  "3": "Executing",
  "4": "Completed",
  "5": "Partial Completed",
  "6": "Signed",
  "7": "Stucking",
};

export const enumNewOperationBatchStatus = {
  SubmitFailed: "-4",
  SignFailed: "-3",
  Failed: "-2",
  Rejected: "-1",
  Requested: "1",
  Submitted: "2",
  Executing: "3",
  Completed: "4",
  PartialCompleted: "5",
  Signed: "6",
  Stucking: "7",
} as const;

export const enumMerchantOperationStatus: FreeObj = {
  "-4": "SubmitFailed",
  "-3": "VerifyFailed",
  "-2": "SignFailed",
  "-1": "Rejected",
  "0": "Pending",
  "1": "ReceivedAll",
  "2": "Approved",
  "3": "Prepared",
  "4": "Signed",
  "5": "Verified",
};
export const enumNewMerchantOperationStatus = {
  SubmitFailed: "-4",
  VerifyFailed: "-3",
  SignFailed: "-2",
  Rejected: "-1",
  Pending: "0",
  ReceivedAll: "1",
  Approved: "2",
  Prepared: "3",
  Signed: "4",
  Verified: "5",
} as const;
export const enumLanguage = {
  en: "en",
  zh_cn: "zh_cn",
} as const;

export const enumOperationTransactionStatus: FreeObj = {
  "-2": "BroadcastFailed",
  "-1": "ConfirmedFail",
  "1": "Signed",
  "2": "Broadcasted",
  "3": "Unconfirmed",
  "4": "ConfirmedSuccess",
};
export const enumOperationTransactionStatusNonceTool: FreeObj = {
  BroadcastFailed: "-2",
  ConfirmedFail: "-1",
  Signed: "1",
  Broadcasted: "2",
  Unconfirmed: "3",
  ConfirmedSuccess: "4",
};
export const enumNonceToolPriorityListStatus: FreeObj = {
  Used: "1",
  Unused: "0",
  Invalid: "2",
  Deleted: "3",
};

export const enumWalletStatus: FreeObj = {
  "-2": "Deleted",
  "-1": "Disabled",
  "0": "Unassigned",
  "1": "Enabled",
};

export const enumWithdrawStatus: FreeObj = {
  Enable: "1",
  Disable: "-1",
};
export const enumWithdrawStatusCol: FreeObj = {
  "-1": "Disable",
  "1": "Enable",
};

export const enumWalletType: FreeObj = {
  "0": "External Wallet",
  // "0": "Address Book",
  "1": "Client Wallet",
  "2": "Hot Wallet",
  "3": "Invoker Wallet",
  "4": "Sweep Dest Wallet",
  "5": "Settlement Wallet",
};

export const enumDirectionObj: FreeObj = {
  "1": "In",
  "2": "Out",
};
export const enumCertifiedBalanceObj: FreeObj = {
  true: "Yes",
  false: "No",
};

export const enumIsFeeObj: FreeObj = {
  false: "No",
  true: "Yes",
};

export const enumTransactionStatus: FreeObj = {
  "-2": "FastConfirmedFail",
  "-1": "ConfirmedFail",
  "1": "Unconfirmed",
  "2": "ConfirmedSuccess",
  "3": "Invalid",
  "4": "FastConfirmedSuccess",
};

// Can also be used for enum_merchant_signer_status
export const enumMerchantStatus: FreeObj = {
  "1": "Enabled",
  "2": "Disabled",
};

export const enumTransationType: FreeObj = {
  "0": "External Transfer",
  "1": "Client Deposit",
  "2": "Hot Wallet Deposit",
  "3": "Invoker Deposit",
  "8": "Adjustment",
  "9": "External Miner Fee",
  "10": "LOSS10",
  "11": "CAUTION11",
  "12": "Sweep to Hot Wallet",
  "13": "CAUTION13",
  "14": "Sweep",
  "15": "CAUTION15",
  "19": "MinerFee",
  "20": "Withdraw",
  "21": "Withdraw Deposit",
  "22": "Hot Wallet Transfer",
  "23": "CAUTION23",
  "24": "CAUTION24",
  // "25": "CAUTION25",
  "25": "Settlement",
  "29": "Withdraw Fee",
  "30": "LOSS30",
  "31": "Client Wallet Topup",
  "32": "Hot Wallet Topup",
  "33": "Invoker Transfer",
  "34": "CAUTION34",
  "35": "CAUTION35",
  "39": "Topup Fee",
};
export const tableConfig = {
  pageSize: 20,
  rowsPerPageOptions: [20],
  defaultWidth: 100,
  addressWidth: 250,
  dateWidth: 150,
  freeTextWidth: 200,
  addressFields: [
    "from_address",
    "to_address",
    "gether_address",
    "invoker_address",
    "hot_wallet_address",
    "wallet_address",
    "Receiving Address",
    "Invoker Address",
    "Sending Address",
    "Wallet Address",
    "tx_hash",
  ],
  dateFields: [
    "created_date",
    "date_operated",
    "last_modified_time",
    "last_modified_date",
    "date_last_modified",
    "date_approved",
    "wallet_settlement_date",
    "trans_date",
    "approved_date",
    "date_created",
    "date_created",
    "trans_date",
    "mark_date",
    "date_received",
    "date_last_callback_attempt",
    "creation_time",
    "operation_time",
    "frozen_date",
    "unfrozen_date",
    "mark_date_from",
    "mark_date_to",
  ],
  freeTextFields: [
    "client_id",
    "wallet_name",
    "amount",
    "frozen_amount",
    "adjustment_amount",
  ],
};
export const dataGridDefaults = {
  autoHeight: true,
  disableColumnFilter: true,
  disableSelectionOnClick: true,
  headerHeight: 45,
  rowHeight: 40,
  paginationMode: "server",
  pageSize: tableConfig.pageSize,
  rowsPerPageOptions: tableConfig.rowsPerPageOptions,
} as const;

export const enumCallbackStatus: FreeObj = {
  "-1": "Retry",
  "0": "Incompleted",
  "1": "Completed",
};

export const enumApprovalStatus: Record<number | string, undefined | string> = {
  "-1": "Rejected",
  "1": "Approved",
};
export const enumNewApprovalStatus = {
  Rejected: "-1",
  Approved: "1",
} as const;

export const enumAdjustmentStatus: FreeObj = {
  "-1": "Rejected",
  "0": "Pending",
  "1": "Approved",
};
export const enumMessageDataTypes = {
  hotwallet_deposit_status: "HotWalletDepositStatus",
  deposit_status: "Deposit",
  operation_status: "Operation",
  operation_batch_status: "Operationbatch",
  ledger_deposit: "LedgerDeposit",
  ledger_withdraw: "LedgerWithdraw",
  ledger_transfer: "LedgerTransfer",
  ledger_transaction: "LedgerTransaction",
  // block_number: "BlockNumber",
};
export const callbackSettingSendTypes = {
  true: "yes",
  false: "no",
};
export const enumMessageCallbackStatus: FreeObj = {
  "1": "Completed",
  "0": "Pending",
  "-1": "PushErr",
  "-2": "Error",
};
// export const enumAddressBookProposalType = {
//   "0": "AddAddress",
//   "1": "DeleteAddress",
// } as const;
export const enumAddressBookApprovalStatus = {
  "-1": "Rejected",
  "1": "PendingForApproval",
  "2": "Approved",
} as const;
export const enumWithdrawCheckingTimeControlApprovalStatus = {
  "-1": "Rejected",
  "0": "Pending",
  "1": "Approved",
} as const;

export const enumAddressBookProposalType: FreeObj = {
  "10": "Add Record",
  "20": "Edit Record",
  "30": "Delete Record",
};

export const enumPushDepositMessage: FreeObj = {
  Enable: "1",
  Disable: "-1",
};

export const EnumHoursStart24HrsFormat = {
  "00:00": "00:00:00",
  "01:00": "01:00:00",
  "02:00": "02:00:00",
  "03:00": "03:00:00",
  "04:00": "04:00:00",
  "05:00": "05:00:00",
  "06:00": "06:00:00",
  "07:00": "07:00:00",
  "08:00": "08:00:00",
  "09:00": "09:00:00",
  "10:00": "10:00:00",
  "11:00": "11:00:00",
  "12:00": "12:00:00",
  "13:00": "13:00:00",
  "14:00": "14:00:00",
  "15:00": "15:00:00",
  "16:00": "16:00:00",
  "17:00": "17:00:00",
  "18:00": "18:00:00",
  "19:00": "19:00:00",
  "20:00": "20:00:00",
  "21:00": "21:00:00",
  "22:00": "22:00:00",
  "23:00": "23:00:00",
} as const;

export const EnumHoursEnd24HrsFormat = {
  "00:59": "00:59:59",
  "01:59": "01:59:59",
  "02:59": "02:59:59",
  "03:59": "03:59:59",
  "04:59": "04:59:59",
  "05:59": "05:59:59",
  "06:59": "06:59:59",
  "07:59": "07:59:59",
  "08:59": "08:59:59",
  "09:59": "09:59:59",
  "10:59": "10:59:59",
  "11:59": "11:59:59",
  "12:59": "12:59:59",
  "13:59": "13:59:59",
  "14:59": "14:59:59",
  "15:59": "15:59:59",
  "16:59": "16:59:59",
  "17:59": "17:59:59",
  "18:59": "18:59:59",
  "19:59": "19:59:59",
  "20:59": "20:59:59",
  "21:59": "21:59:59",
  "22:59": "22:59:59",
  "23:59": "23:59:59",
} as const;

export const EnumHoursEnd24HrsFormatWithSec = {
  "00:59:59": "00:59:59",
  "01:59:59": "01:59:59",
  "02:59:59": "02:59:59",
  "03:59:59": "03:59:59",
  "04:59:59": "04:59:59",
  "05:59:59": "05:59:59",
  "06:59:59": "06:59:59",
  "07:59:59": "07:59:59",
  "08:59:59": "08:59:59",
  "09:59:59": "09:59:59",
  "10:59:59": "10:59:59",
  "11:59:59": "11:59:59",
  "12:59:59": "12:59:59",
  "13:59:59": "13:59:59",
  "14:59:59": "14:59:59",
  "15:59:59": "15:59:59",
  "16:59:59": "16:59:59",
  "17:59:59": "17:59:59",
  "18:59:59": "18:59:59",
  "19:59:59": "19:59:59",
  "20:59:59": "20:59:59",
  "21:59:59": "21:59:59",
  "22:59:59": "22:59:59",
  "23:59:59": "23:59:59",
} as const;

export const EnumHoursStart24HrsFormatWithSec = {
  "00:00:00": "00:00:00",
  "01:00:00": "01:00:00",
  "02:00:00": "02:00:00",
  "03:00:00": "03:00:00",
  "04:00:00": "04:00:00",
  "05:00:00": "05:00:00",
  "06:00:00": "06:00:00",
  "07:00:00": "07:00:00",
  "08:00:00": "08:00:00",
  "09:00:00": "09:00:00",
  "10:00:00": "10:00:00",
  "11:00:00": "11:00:00",
  "12:00:00": "12:00:00",
  "13:00:00": "13:00:00",
  "14:00:00": "14:00:00",
  "15:00:00": "15:00:00",
  "16:00:00": "16:00:00",
  "17:00:00": "17:00:00",
  "18:00:00": "18:00:00",
  "19:00:00": "19:00:00",
  "20:00:00": "20:00:00",
  "21:00:00": "21:00:00",
  "22:00:00": "22:00:00",
  "23:00:00": "23:00:00",
} as const;

export const batchIdUrlString = "batchId=";
export const merchantOrderIdString = "merchant_order_id=";
export const bankAddressArr = ["0x0000000000000000000000000000000000000000"];
export const csvRecordDivisor = 100000;
export const csvRecordPageSize = 500;
export const defaultIntervalPendingOperationsCount = "30"; //second
export const approvalAutoString = "auto";
export const inputCharacterMaxLength = 256;
export const multipleInputCharacterMaxLength = 1024;
export const FeatureCodes = {
  assetManagement: nodetypes.FeaturesAssetManagement,
  walletManagement: nodetypes.FeaturesWalletManagement,
  report: nodetypes.FeaturesReport,
  tools: nodetypes.FeaturesTools,
  ledger: nodetypes.FeaturesLedger,
};
export const FeatureCodeYubikeyRegister =
  nodetypes.FeaturesTools.YubiKeyRegister.prefix;
